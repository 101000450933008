import { CONNECTED_ERP_SERVICE_FAILED, CONNECTED_ERP_SERVICE_SUCCESS } from "constants/ERP_CONSTANTS";

export const getERPConnectionCode = (isConnectERPService: boolean) => {
    return isConnectERPService
            ? CONNECTED_ERP_SERVICE_SUCCESS
            : CONNECTED_ERP_SERVICE_FAILED;
}

export const getIsConnectERPService = (connectERPService?: string) => {
    return connectERPService === CONNECTED_ERP_SERVICE_SUCCESS ? true : false
}