import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const AlertRedIcon: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
        <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 20.8337V15.5003M15 10.167H15.0133M28.3333 15.5003C28.3333 22.8641 22.3638 28.8337 15 28.8337C7.63616 28.8337 1.66663 22.8641 1.66663 15.5003C1.66663 8.13653 7.63616 2.16699 15 2.16699C22.3638 2.16699 28.3333 8.13653 28.3333 15.5003Z" stroke="#FF3541" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </IconWrapper>
  );
}

export default AlertRedIcon;