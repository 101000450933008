import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const AvatarFilled: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 7C7.69223 7 8.36892 6.79473 8.9445 6.41015C9.52007 6.02556 9.96867 5.47894 10.2336 4.83939C10.4985 4.19985 10.5678 3.49612 10.4327 2.81719C10.2977 2.13825 9.96436 1.51461 9.47487 1.02513C8.98539 0.535644 8.36175 0.202301 7.68282 0.0672531C7.00388 -0.0677952 6.30015 0.00151649 5.66061 0.266423C5.02107 0.53133 4.47444 0.979934 4.08986 1.55551C3.70527 2.13108 3.5 2.80777 3.5 3.5C3.5 4.42826 3.86875 5.3185 4.52513 5.97488C5.1815 6.63125 6.07174 7 7 7ZM7 8C4.83063 8 0.5 9.34 0.5 12V14H13.5V12C13.5 9.34 9.16937 8 7 8Z" fill="black"/>
      </svg>
    </IconWrapper>
  );
}

export default AvatarFilled;