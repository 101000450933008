import React, { useMemo } from 'react';
import { IIconWrapperProps } from './Wrapper';

import * as icons from './icons';

const ICONS = {
  'arrow_circle_left': {
    light: icons.ArrowCircleLeftLight,
    regular: icons.ArrowCircleLeftRegular,
    solid: icons.ArrowCircleLeftSolid,
  },
  'arrow_from_bracket': {
    light: icons.ArrowFromBracketLight,
    regular: icons.ArrowFromBracketRegular,
    solid: icons.ArrowFromBracketSolid,
  },
  'book': {
    light: icons.BookLight,
    regular: icons.BookRegular,
    solid: icons.BookSolid,
  },
  'document_check': {
    light: icons.DocumentCheckLight,
    regular: icons.DocumentCheckRegular,
    solid: icons.DocumentCheckSolid,
  },
  'eye': {
    light: icons.EyeLight,
    regular: icons.EyeRegular,
    solid: icons.EyeSolid,
  },
  'eye_slash': {
    light: icons.EyeSlashLight,
    regular: icons.EyeSlashRegular,
    solid: icons.EyeSlashSolid,
  },
  'gauge': {
    light: icons.GaugeLight,
    regular: icons.GaugeRegular,
    solid: icons.GaugeSolid,
  },
  'user': {
    light: icons.UserLight,
    regular: icons.UserRegular,
    solid: icons.UserSolid,
  },
  'users': {
    light: icons.UsersLight,
    regular: icons.UsersRegular,
    solid: icons.UsersSolid,
  },
  'arrow_left': {
    light: icons.ArrowLeftLight,
    regular: icons.ArrowLeftRegular,
    solid: icons.ArrowLeftSolid,
  },
  'chevron_left': {
    light: icons.ChevronLeftLight,
    regular: icons.ChevronLeftRegular,
    solid: icons.ChevronLeftSolid,
  },
  'hamburguer': {
    light: icons.HamburguerLight,
    regular: icons.HamburguerRegular,
    solid: icons.HamburguerSolid,
  },
  'chevron_right': {
    light: icons.ChevronRightLight,
    regular: icons.ChevronRightRegular,
    solid: icons.ChevronRightSolid,
  },
  'search': {
    light: icons.SearchLight,
    regular: icons.SearchRegular,
    solid: icons.SearchSolid,
  },
  'info': {
    light: icons.InfoLight,
    regular: icons.InfoRegular,
    solid: icons.InfoSolid,
  },
  'star': {
    light: icons.StarLight,
    regular: icons.StarRegular,
    solid: icons.StarSolid,
  },
  'close_circle': {
    light: icons.CloseCircleLight,
    regular: icons.CloseCircleRegular,
    solid: icons.CloseCircleSolid,
  },
  'close_mark': {
    light: icons.CloseMarkLight,
    regular: icons.CloseMarkRegular,
    solid: icons.CloseMarkSolid,
  },
  'pen': {
    light: icons.PenLight,
    regular: icons.PenRegular,
    solid: icons.PenSolid,
  },
  'circle_check': {
    light: icons.CircleCheckLight,
    regular: icons.CircleCheckRegular,
    solid: icons.CircleCheckSolid,
  },
  'check_list': {
    light: icons.CheckListLight,
    regular: icons.CheckListRegular,
    solid: icons.CheckListSolid,
  },
  'document': {
    light: icons.DocumentLight,
    regular: icons.DocumentRegular,
    solid: icons.DocumentSolid,
  },
  'user_gear': {
    light: icons.UserGearLight,
    regular: icons.UserGearRegular,
    solid: icons.UserGearSolid,
  },
  'upload': {
    light: icons.UploadLight,
    regular: icons.UploadRegular,
    solid: icons.UploadSolid,
  },
  'plus': {
    light: icons.PlusLight,
    regular: icons.PlusRegular,
    solid: icons.PlusSolid,
  },
  'pen_square': {
    light: icons.PenSquareLight,
    regular: icons.PenSquareRegular,
    solid: icons.PenSquareSolid,
  },
  'trash_can': {
    light: icons.TrashCanLight,
    regular: icons.TrashCanRegular,
    solid: icons.TrashCanSolid,
  },
  'calendar': {
    light: icons.CalendarLight,
    regular: icons.CalendarRegular,
    solid: icons.CalendarSolid,
  },
  'link': {
    light: icons.LinkLight,
    regular: icons.LinkRegular,
    solid: icons.LinkSolid,
  },
  'carret_small_down': {
    light: icons.CarretSmallDownLight,
    regular: icons.CarretSmallDownRegular,
    solid: icons.CarretSmallDownSolid,
  },
  'carret_small_up': {
    light: icons.CarretSmallUpLight,
    regular: icons.CarretSmallUpRegular,
    solid: icons.CarretSmallUpSolid,
  },
  'sort': {
    light: icons.SortLight,
    regular: icons.SortRegular,
    solid: icons.SortSolid,
  },
  'link_dash': {
    light: icons.LinkDashLight,
    regular: icons.LinkDashRegular,
    solid: icons.LinkDashSolid,
  },
  'person_female': {
    light: icons.PersonFemaleLight,
    regular: icons.PersonFemaleRegular,
    solid: icons.PersonFemaleSolid,
  },
  'person_male': {
    light: icons.PersonMaleLight,
    regular: icons.PersonMaleRegular,
    solid: icons.PersonMaleSolid,
  },
  'building': {
    light: icons.BuildingLight,
    regular: icons.BuildingRegular,
    solid: icons.BuildingSolid,
  },
  'filter_list': {
    light: icons.FilterListRegular,
    regular: icons.FilterListRegular,
    solid: icons.FilterListRegular,
  }
  ,
  'share': {
    light: icons.ShareLight,
    regular: icons.ShareRegular,
    solid: icons.ShareSolid,
  },
  'bar_code': {
    light: icons.BarCodeLight,
    regular: icons.BarCodeRegular,
    solid: icons.BarCodeRegular,
  },
  'wifi': {
    light: icons.WifiLight,
    regular: icons.WifiRegular,
    solid: icons.WifiRegular,
  }
  ,
  'arrows_rotate': {
    light: icons.ArrowsRotateLight,
    regular: icons.ArrowsRotateRegular,
    solid: icons.ArrowsRotateRegular,
  }
  ,
  'clock_rotate_left': {
    light: icons.ClockRotateLeftLight,
    regular: icons.ClockRotateLeftRegular,
    solid: icons.ClockRotateLeftRegular,
  }
  ,
  'branch_building': {
    light: icons.BranchBuildingRegular,
    regular: icons.BranchBuildingRegular,
    solid: icons.BranchBuildingRegular,
  },
  'download_file': {
    light: icons.DownloadFileRegular,
    regular: icons.DownloadFileRegular,
    solid: icons.DownloadFileRegular,
  },
  'logout': {
    light: icons.Logout,
    regular: icons.Logout,
    solid: icons.Logout,
  },
  'locker': {
    light: icons.Locker,
    regular: icons.Locker,
    solid: icons.Locker,
  },
  'help': {
    light: icons.Help,
    regular: icons.Help,
    solid: icons.Help,
  },
  'avatar': {
    light: icons.Avatar,
    regular: icons.Avatar,
    solid: icons.Avatar,
  },
  'arrow_up': {
    light: icons.ArrowUp,
    regular: icons.ArrowUp,
    solid: icons.ArrowUp,
  },
  'arrow_down': {
    light: icons.ArrowDown,
    regular: icons.ArrowDown,
    solid: icons.ArrowDown,
  },
  'round_alert': {
    light: icons.RoundAlert,
    regular: icons.RoundAlert,
    solid: icons.RoundAlert,
  },
  'account_value_red': {
    light: icons.AccountValueRed,
    regular: icons.AccountValueRed,
    solid: icons.AccountValueRed,
  },
  'account_value_blue': {
    light: icons.AccountValueBlue,
    regular: icons.AccountValueBlue,
    solid: icons.AccountValueBlue,
  },
  'nfe_blue': {
    light: icons.NfeBlue,
    regular: icons.NfeBlue,
    solid: icons.NfeBlue,
  },
  'nfe_red': {
    light: icons.NfeRed,
    regular: icons.NfeRed,
    solid: icons.NfeRed,
  },
  'document_check_blue': {
    light: icons.DocumentCheckBlue,
    regular: icons.DocumentCheckBlue,
    solid: icons.DocumentCheckBlue,
  },
  'round_alert_green': {
    light: icons.RoundAlertGreen,
    regular: icons.RoundAlertGreen,
    solid: icons.RoundAlertGreen,
  },
  'avatar_filled': {
    light: icons.AvatarFilled,
    regular: icons.AvatarFilled,
    solid: icons.AvatarFilled,
  },
  'lock_closed': {
    light: icons.LockClosed,
    regular: icons.LockClosed,
    solid: icons.LockClosed,
  },
  'alert_red_icon': {
    light: icons.AlertRedIcon,
    regular: icons.AlertRedIcon,
    solid: icons.AlertRedIcon
  }
}

export type TIcons = keyof typeof ICONS;
export type TIconType = 'light' | 'regular' | 'solid';
export type TIconName = `${TIcons}-${TIconType}`;

export interface IIconProps extends Omit<IIconWrapperProps, 'children'> {
  icon: TIconName;
}

const Icon: React.FC<IIconProps> = ({ icon, ...rest }) => {
  const Comp = useMemo(() => {
    const [iconName, iconType] = icon.split('-') as [TIcons, TIconType];
    return ICONS[iconName][iconType];
  }, [icon])
  return <Comp {...rest} />;
}

export default Icon;