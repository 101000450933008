import styled, { createGlobalStyle, css } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: 'Open Sans';
  }
  html, body, main.container {
    display: flex;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }
  #root {
    flex: 1;
  }
  input[type=password]::-ms-reveal, input[type=password]::-ms-clear {
    display: none;
  }
  a {
    color: #000;
    text-decoration: none;
    &:active {
      background: none;
    }
  }
  button {
    border: none;
    background: none;
  }
  

  *::-webkit-scrollbar-track
  {
    background-color: ${({ theme }) => theme.neutrals.pureWhite};
  }

  *::-webkit-scrollbar
  {
    width: 8px;
    background-color: ${({ theme }) => theme.neutrals.pureWhite};
  }

  *::-webkit-scrollbar-thumb
  {
    background-color: rgba(75, 75, 75, .8 );
    border-radius: 5px;
  }
  
  /* .sc-iqcoie {
    &, * {
      z-index: 99;
    }
    div ul {
      max-height: 50vh;
      overflow: auto;
    }
  } */
    
  .btn-secundary-outline{
    border-color: ${({ theme }) => theme.brand.color.primary.pure};
    border-style: solid;
    border-width: 1px;
    background-color:  ${({ theme }) => theme.neutrals.pureWhite};
    color: ${({ theme }) => theme.brand.color.primary.pure} !important;
    &:hover {
      border-color: ${({ theme }) => theme.brand.color.primary.pure};
      border-style: solid;
      border-width: 1px;
      background-color:  ${({ theme }) => theme.neutrals.gray1};
    }
  }

  .default-toast-custom-body,
  .toast-custom-body {
    white-space: pre-line;
    font-size: 14px;
  }

  .default-toast-custom-body #toast-title,
  .toast-custom-body #toast-title {
    font-size: 18px;
    line-height: 1.5;
    font-weight: bold;
  }

  .default-toast-custom-body #toast-description,
  .toast-custom-body #toast-description {
    line-height: 1.5;
  }

  .toast-custom-body #toast-description {
    font-weight: bold;
  }

  .toast-custom-body #toast-description::before {
    content:  "Por favor, verifique os dados digitados e tente novamente!";
    display: block;
    font-weight: normal;
  }

  .toast-custom-body #toast-description::after {
    content: "será necessário trocar a senha.";
    font-weight: normal;
  }
`;

export * from "./theme";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => css`
    background-color: ${theme.neutrals.gray1};
    @media screen and (max-width: ${theme.brand.media_query.tablet}) {
      .paper {
        width: 346px;
        padding: ${`${theme.spacing.xs}px ${theme.spacing.xxs}px`};
      }
    }
    @media screen and (max-width: ${theme.brand.media_query.mobile}) {
      background-color: ${theme.neutrals.pureWhite};
      .paper {
        width: 100%;
        height: 100%;
        box-shadow: unset;
        padding: ${`${theme.spacing.xxxs}px ${theme.spacing.xxs}px`};
      }
      [data-title] {
        margin-bottom: ${theme.spacing.sm}px;
      }
      [data-info] {
        margin-bottom: ${theme.spacing.xxs}px;
      }
    }
  `}
`;

export const Logo = styled.img`
  ${({ theme }) => css`
    max-width: 200px;
    margin-bottom: ${({ theme }) => theme.spacing.xs}px;
    @media screen and (max-width: ${theme.brand.media_query.mobile}) {
      margin-bottom: ${({ theme }) => theme.spacing.sm}px;
    }
  `}
`;

export const PrivateContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.xxxs}px;
`;

export const LoadContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
