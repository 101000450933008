import axios from 'axios';
import { STORE_KEY } from 'constants/APP_CONSTANTS';
import publics from 'routes/publics';

const baseURL = process.env.REACT_APP_API

export let axiosRequestHistory: string[] = [];

const redirectToServiceUnavailable = () => {
    const erroPath = '#/503';
    window.location.hash = erroPath;
};

const api = axios.create({
    baseURL
});

api.interceptors.request.use((config) => {
    if (config?.url) {
        axiosRequestHistory.push(config.url);
        if (axiosRequestHistory.length > 2) {
            axiosRequestHistory.shift();
        }
    }
    return config;
});

api.interceptors.response.use(
    (response) => {
        if(process.env.NODE_ENV === "development"){
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(response);
                }, 1000);
            });
        }
        return response;
    },
    (error) => {
        if (error?.response) {
            const status = error.response.status as number;
            if (status === 401) {
                const hash = window.location.hash
                const pathname = hash.includes('#/') ? hash.split('#/')[1] : hash
                const publicRoutes = publics.filter(({path}) => path.includes(pathname))
                if(!publicRoutes.length){
                    localStorage.removeItem(STORE_KEY);
                    window.location.reload();
                }
            }
          }
        console.clear();

        return Promise.reject(error)
    }
);

export default api;