import { ATTEMPT_LOGIN_RESET_INTERVAL, ATTEPTS_USERS_FROMDB, FIRST_ACCESS_REQUERED_LOCAL_STORAGE_KEY, FORGOT_PASSWORD_REQUIRED_LOCAL_STORAGE_KEY } from "constants/LOGIN_ATTEMPTS";

interface ILoginAttemptsValue {
    document: string,
    loginAttempts: number,
    lastLoginAttempt: Date
}

interface IUpdateLoginAttemptsValue {
    key: string;
    document: string;
    loginAttempts?: number,
    lastLoginAttempt?: Date
}

const removeMask = (value: string) => {
    return value.replace(/\D/g, '');
};

const updateAttempts = ({key, document, loginAttempts, lastLoginAttempt} : IUpdateLoginAttemptsValue) => {
    const currentTime = new Date().getTime();
    const storedAttempts = localStorage.getItem(key);
    let attempts = storedAttempts ? JSON.parse(storedAttempts) : [];

    let userAttempt = attempts.find((attempt: ILoginAttemptsValue) => attempt.document === document);

    if (userAttempt) {
        if (loginAttempts && lastLoginAttempt) {
            userAttempt.loginAttempts = loginAttempts;
            userAttempt.lastLoginAttempt = new Date(lastLoginAttempt);
        } else {
            const lastAttemptTime = new Date(userAttempt.lastLoginAttempt).getTime();

            if (currentTime - lastAttemptTime < ATTEMPT_LOGIN_RESET_INTERVAL) {
                userAttempt.loginAttempts += 1;
            } else {
                userAttempt.loginAttempts = 1;
                userAttempt.lastLoginAttempt = new Date();
            }
        }
    } else {
        userAttempt = {
            document,
            loginAttempts: loginAttempts ? loginAttempts : 1,
            lastLoginAttempt: lastLoginAttempt ? new Date(lastLoginAttempt) : new Date()
        };
        attempts.push(userAttempt);
    }

    localStorage.setItem(key, JSON.stringify(attempts));

    return userAttempt;
};

export const notFoundUserLocalStorageData = (document: string, forgotPasswordIsRequired?: boolean, firstAccessIsRequired?: boolean) => {
    if (forgotPasswordIsRequired) return  updateAttempts({key: FORGOT_PASSWORD_REQUIRED_LOCAL_STORAGE_KEY, document: removeMask(document)})
    if (firstAccessIsRequired) return updateAttempts({key: FIRST_ACCESS_REQUERED_LOCAL_STORAGE_KEY, document: removeMask(document)})
    return null;
    
}

export const attemptsUsersFromDbOnLocalStorageData = (loginAttemptsValue: ILoginAttemptsValue) => {
    const {document, ...rest} = loginAttemptsValue
   return updateAttempts({key: ATTEPTS_USERS_FROMDB, ...rest, document: removeMask(document)})
}

export const hasRecentLoginAttempt = (document: string) => {
    const currentTime = new Date().getTime();
    const storedAttempts = localStorage.getItem(ATTEPTS_USERS_FROMDB);
    let attempts = storedAttempts ? JSON.parse(storedAttempts) : [];

    let userAttempt = attempts.find((attempt: ILoginAttemptsValue) => attempt.document === document);
    if (!userAttempt) return false;
    const lastAttemptTime = new Date(userAttempt.lastLoginAttempt).getTime();
    const isWithinInterval = currentTime - lastAttemptTime < ATTEMPT_LOGIN_RESET_INTERVAL;

    if (!isWithinInterval) {
        removeUserFromLocalStorage(ATTEPTS_USERS_FROMDB, document);
    }

    return isWithinInterval;
}

export const removeUserFromMultipleLocalStorageKeys = (keys: string[], document: string): void => {
    keys.forEach((key) => removeUserFromLocalStorage(key, document));
};

export const removeUserFromLocalStorage = (key: string, document: string) => {
    const storedAttempts = localStorage.getItem(key);
    if (storedAttempts) {
        const attempts = JSON.parse(storedAttempts);
        const updatedAttempts = attempts.filter((attempt: ILoginAttemptsValue) => attempt.document !== removeMask(document));
        localStorage.setItem(key, JSON.stringify(updatedAttempts));
    }
};
const clearOldEntries = (key: string, currentTime: number) => {
    const storedAttempts = localStorage.getItem(key);
    if (storedAttempts) {
        const attempts = JSON.parse(storedAttempts);
        const updatedAttempts = attempts.filter((attempt: ILoginAttemptsValue) => {
            const lastAttemptTime = new Date(attempt.lastLoginAttempt).getTime();
            return currentTime - lastAttemptTime < ATTEMPT_LOGIN_RESET_INTERVAL;
        });
        localStorage.setItem(key, JSON.stringify(updatedAttempts));
    }
};

export const clearMultipleOldLocalStorageEntries = (keys: string[], currentTime: number): void => {
    keys.forEach((key) => clearOldEntries(key, currentTime));
};

export const clearOldLocalStorageEntries = () => {
    const currentTime = new Date().getTime();
    clearMultipleOldLocalStorageEntries([FORGOT_PASSWORD_REQUIRED_LOCAL_STORAGE_KEY, FIRST_ACCESS_REQUERED_LOCAL_STORAGE_KEY, ATTEPTS_USERS_FROMDB], currentTime)
};

