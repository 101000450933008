import React from 'react';
import IconWrapper, { IIconWrapperProps } from '../Wrapper';

const LockClosed: React.FC<Omit<IIconWrapperProps, 'children'>> = (props) => {
  return (
    <IconWrapper {...props}>
        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.125 6H9V3.5C9 2.70435 8.68393 1.94129 8.12132 1.37868C7.55871 0.81607 6.79565 0.5 6 0.5C5.20435 0.5 4.44129 0.81607 3.87868 1.37868C3.31607 1.94129 3 2.70435 3 3.5V6H0.875C0.775544 6 0.680161 6.03951 0.609835 6.10984C0.539509 6.18016 0.5 6.27554 0.5 6.375V15.125C0.5 15.2245 0.539509 15.3198 0.609835 15.3902C0.680161 15.4605 0.775544 15.5 0.875 15.5H11.125C11.2245 15.5 11.3198 15.4605 11.3902 15.3902C11.4605 15.3198 11.5 15.2245 11.5 15.125V6.375C11.5 6.27554 11.4605 6.18016 11.3902 6.10984C11.3198 6.03951 11.2245 6 11.125 6ZM7.8125 6H4.1875V3.47656C4.1875 2.99586 4.37846 2.53484 4.71837 2.19493C5.05828 1.85502 5.5193 1.66406 6 1.66406C6.4807 1.66406 6.94172 1.85502 7.28163 2.19493C7.62154 2.53484 7.8125 2.99586 7.8125 3.47656V6Z" fill="black"/>
        </svg>
    </IconWrapper>
  );
}

export default LockClosed;